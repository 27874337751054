.siimpl {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .logo {
    padding-left: 1.5rem;
  }

  .btn-outline-secondary {
    margin-right: 10px;
    margin-top: 0px;
    border-radius: 2.5px;
    border-color: #e0e0e0;
  }

  .btn-outline-secondary:hover {
    background-color: #1e90ff12;
    color: #6c757d;
  }

  .empty-trash-btn {
    margin-right: 10px;
    margin-right: 0px !important;
    margin-top: 0px;
    border-color: #e0e0e0;
    height: 40px;
    min-width: 140px;
    font-weight: 300;
    border-radius: 5px;
  }

  header {
    min-height: 60px;
    z-index: 90;

    display: flex;
    flex: 0 0 48px;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 28px;

    // box-shadow: inset 0 -1px 0 #e6e6e6;

    .section-left {
      display: flex;
      flex-direction: row;
      align-items: center;

      .siimpl-logo {
        color: white !important;
        font-size: 28px;
        font-weight: 700;
        text-decoration: none !important;
      }
    }

    .section-right {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      margin-right: 8px;
      justify-content: flex-end;
    }

    .search {
      position: relative;
      padding-left: 32px;

      .form-control {
        font-size: 20px !important;
        font-weight: 300;
        width: 35vw;
        height: 40px;
        background: #fbfbfb;
        padding-left: 20px;
        margin: auto !important;
        //box-shadow: 0px 0px 14px 6px #f0f0f0 !important;
        border: solid 0.5px #ebebeb;
        border-radius: 5px;
      }

      .search-result-container {
        max-height: 580px;
        min-height: 100px;
        width: 770px;
        background-color: white;
        position: absolute;
        top: 45px;
        box-shadow: 0px 2px 9px -3px grey;
        border-radius: 5px;
        padding: 10px;
        font-weight: 300;

        .result-item {
          padding: 0 3.5px 3.5px;
          margin-bottom: 5px;
          min-height: 35px;
          position: relative;

          hr {
            margin-bottom: 0.5rem;
          }

          .title {
            font-size: 14px;
            width: 80%;
            cursor: pointer;
          }

          .url {
            font-size: 11px;
            cursor: pointer;
            width: 80%;
            overflow: hidden;
            white-space: pre;
            text-overflow: ellipsis;
          }

          .collection {
            float: right;
            font-size: 11px;
            color: dodgerblue;
            cursor: pointer;
            position: absolute;
            top: 5px;
            right: 5px;
            max-width: 160px;
            text-align: right;
          }
        }

        .result-item:hover {
          background-color: #1e90ff12;
        }
      }

      .search-clear-btn {
        position: absolute;
        top: 10px;
        right: 5px;
      }
    }

    .search-additions {
      min-height: 200px;
      width: 50vw;
      max-width: 680px;
      padding: 15px;
      background: white;
      z-index: 1000000;
      position: absolute;
      top: 40px;
      box-shadow: 0px 8px 11px -3px #aaa7a7;
      visibility: hidden;
      opacity: 0;

      .tag {
        font-weight: 500;
        border-radius: 5px;
        float: left;
        border: solid 2px #45b26b;
        padding: 0 10px;
        color: #45b26b;
        margin-right: 10px;
        margin-bottom: 5px;
        cursor: pointer;
      }
    }

    .search-additions.active {
      visibility: visible;
      opacity: 1;
    }
  }

  .siimpl-container {
    font-size: 14px;
    overflow-y: scroll;
    display: flex;
    color: #212121;
    flex-direction: column;
    overflow-x: hidden;
    width: 100%;

    .siimpl-wrapper {
      width: 100%;
    }

    .siimpl-content {
      width: 100%;
      display: flex;
      justify-content: center;

      .siimpl-sidebar {
        position: sticky;
        top: 0px;
        height: 100%;
        width: 288px;
        z-index: 100;

        .mobile-sidebar-header {
          display: none;
        }

        .collection-options {
          label {
            font-size: 12px;
            margin: 0px;
          }

          hr {
            margin-right: 10%;
          }

          .form-control {
            width: 95%;
            height: 30px;
            background: #f7f7f7;
            padding: 5px;
            margin-bottom: 10px;
            font-size: 14px;
          }

          .btn {
            margin-right: 10px;
            margin-top: 0px;
            border-radius: 2.5px;
            border-color: #e0e0e0;
          }

          .btn:hover {
            background-color: #1e90ff12;
            color: #6c757d;
          }
        }

        .is-adding {
          width: 30px;
          height: 30px;
          background: white;
          border: 0px;
          border-radius: 100% !important;
          border: solid 0.5px grey;
          text-align: center;
          margin-left: 10px;
          cursor: pointer;
          border-color: #e0e0e0;

          svg {
            stroke: black;
            vertical-align: sub;
            width: 18px;
          }
        }

        .is-adding:hover {
          background: linear-gradient(174deg, #bceaf1, #82cbff);
          border: 0px;

          svg {
            stroke: white;
          }
        }

        .sort-btn {
          margin-right: 10px;
          margin-right: 0px !important;
          margin-top: 0px;
          border-radius: 30px !important;
          border-color: #e0e0e0;
          height: 40px;
          min-width: 140px;
          font-weight: 300;

          svg {
            margin-right: 10px;
          }
        }

        .sidebar-content {
          width: 288px;
          padding-right: 0;
          padding-top: 24px;
          box-sizing: border-box;
          position: relative;
          height: calc(100vh - 70px);
          display: flex;
          flex-direction: column;
          padding-left: 24px;
          padding-bottom: 4px;
        }

        .sidebar-section-bottom {
          margin-bottom: 10px !important;
          max-height: unset !important;
          overflow: hidden;
        }

        .sidebar-section-bottom:hover {
          overflow: auto;
        }

        .sidebar-section {
          margin-bottom: 6px;
          position: relative;

          .sidebar-item {
            display: flex;
            justify-content: space-between;
            position: relative;
            padding: 5px;
            color: #777;
            font-weight: 400;
            vertical-align: middle;
            cursor: pointer;
            overflow: auto;
            max-height: 65vh;
            width: calc(100% - 10px);

            border-radius: 5px;
            overflow: hidden;

            a {
              display: flex;
              flex: 1;
              text-decoration: none;
              justify-content: inherit;
              color: inherit;

              &:hover {
                .sidebar-item-indicator {
                  width: 80%;
                }
              }

              .sidebar-item-indicator {
                content: '';
                width: 0;
                height: 2px;
                background: linear-gradient(to right, #698efb, #7afec9);
                transition: width 0.5s ease;

                &_active {
                  width: 80%;
                }
              }
            }

            svg {
              margin-right: 10px;
            }

            span {
              vertical-align: text-top;
            }

            .styled-header {
              font-size: 14px;
              text-transform: capitalize;
            }

            .styled-title {
              margin: 0;
              font-size: 12px;
              color: #969696;
            }

            .collection-edit-btn {
              visibility: hidden;
              opacity: 0;
            }

            .nested-chevron {
              position: absolute;
              top: 5px;
              height: 100%;
              width: 50px;
              right: 10px;
              margin: 0;
              float: right;
              z-index: 1000;
              text-align: right;

              svg {
                margin: 0;
              }
            }

            .nested-chevron.rotate {
              transform: rotateX(180deg);
              transition: 0.4s;
            }
          }

          .sidebar-item:hover {
            border-radius: 5px;

            .collection-edit-btn {
              visibility: visible;
              opacity: 1;
            }
          }

          .drag-over--active {
            .sidebar-item {
              background: #e2f1ff;
            }
          }
        }

        .sub-section {
          font-size: 16px;
          margin-bottom: 10px;
        }

        .nested-chevron {
          color: #6b6b6b;
        }

        .nested-collection {
          height: 0px;
          transition: 0.4s;
          overflow: hidden;

          .sidebar-item {
            background-color: #ececec;
          }
        }

        .nested-collection.show {
          transition: 0.4s;
          height: auto;
        }

        /* width */
        ::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #b5b5b5;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #898989;
        }
      }

      .siimpl-container-right {
        padding-left: 0;
        padding-right: 20px;
        width: 100%;
        box-sizing: border-box;
        height: 100%;

        .styled-header {
          margin-bottom: 32px;
          margin: 0px;
          font-size: 24px;
          letter-spacing: -0.56px;
          text-transform: capitalize;
          font-weight: 200;
          color: #828282;

          .owner-circle {
            width: 30px;
            height: 30px;
            background: #b5b5b5;
            border-radius: 100%;
            text-align: center;
            color: white;
            text-transform: capitalize;
            font-size: 17px;
            padding-top: 5px;
            margin-left: 10px;
            cursor: pointer;
          }

          .is-shared,
          .is-adding {
            width: 40px;
            height: 40px;
            background: white;
            border: solid 0.5px grey;
            border-color: #e0e0e0;
            border-radius: 100% !important;
            text-align: center;
            margin-left: 10px;
            cursor: pointer;

            svg {
              stroke: black;
              vertical-align: sub;
            }
          }

          .is-shared:hover,
          .is-adding:hover {
            background: linear-gradient(174deg, #bceaf1, #82cbff);
            border: 0px;

            svg {
              stroke: white;
            }
          }
        }

        .styled-title {
          margin: 0;
          font-size: 12px;
          color: #969696;
        }

        .siimpl-sort {
          .sort-btn {
            margin-right: 10px;
            margin-right: 0px !important;
            margin-top: 0px;
            border-radius: 30px !important;
            border-color: #e0e0e0;
            height: 40px;
            min-width: 140px;
            font-weight: 300;

            svg {
              margin-right: 10px;
            }
          }

          .btn {
            margin-right: 10px;
            margin-top: 0px;
            border-radius: 2.5px;
            border-color: #e0e0e0;
          }

          .btn:hover {
            background-color: #1e90ff12;
            color: #6c757d;
          }
        }

        .siimpl-grid.list {
          grid-template-columns: repeat(1, minmax(240px, 1fr)) !important;
          grid-row-gap: 7.5px;
        }

        .siimpl-grid.list-img {
          grid-template-columns: repeat(6, minmax(240px, 1fr)) !important;
        }

        .siimpl-grid {
          display: flex;
          display: grid;
          margin-top: 16px;

          .siimpl-item {
            height: 260px !important;
            cursor: pointer;
            text-decoration: none;
            color: #212121;
            border: solid 0.5px #e8e8e8;
            border-radius: 5px;
          }

          .siimpl-item-wrapper {
            //border: 1px solid #ededed;
            //box-shadow: 0px 0px 14px 6px #ededed;
            height: 100%;
            padding: 16px;
            position: relative;
            flex-direction: column;
            width: 100%;
            box-sizing: border-box;
            border-radius: 4px;

            .siimpl-icon {
              margin-bottom: 8px;

              img {
                width: 45px;
                height: 45px;
                object-fit: cover;
                border-radius: 50%;
              }
            }

            .siimpl-img {
              margin-bottom: 2px;
              width: 100%;
              height: 80%;

              img {
                width: 100%;
                height: 100%;
                object-fit: scale-down;
              }
            }

            .title {
              color: #212121;
              max-height: 44px;
              word-break: break-word;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;

              h6 {
                font-size: 15px;
                margin: 0;
              }
            }

            .clamp-one-line {
              -webkit-line-clamp: 1;
            }

            .link {
              white-space: pre;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
            }

            .description {
              max-height: 80px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }

            .description,
            .link {
              .text-small {
                font-size: 12px;
                color: rgb(107, 107, 107);
              }

              a {
                color: dodgerblue !important;
              }
            }

            .details {
              margin-top: 16px;
              color: rgb(107, 107, 107);
              position: absolute;
              bottom: 0;
              width: 100%;
              left: 0;
              padding: 0 20px;

              svg {
                float: right;
              }

              .btn-group {
                float: right;
                color: grey;
              }

              .dropdown-toggle::after {
                display: none;
              }
            }

            .multi-select-toggle {
              opacity: 1;
              position: absolute;
              top: 5px;
              right: 5px;
            }
          }
        }
      }
    }

    .collection-options {
      button {
        border: none !important;
        outline: none !important;
        background-color: transparent !important;
      }

      .dropdown-menu {
        font-size: 14px;
      }
    }

    .collection-content {
      font-size: 15px;
      padding: 5px;
      margin-bottom: 8px;
      color: #777777;
      font-weight: 400;
      cursor: pointer;
      overflow: auto;
    }

    .collection-content:hover {
      background-color: #1e90ff12;
      border-radius: 5px;
    }

    .search {
      width: 100%;
      height: 30px;
      background: #f7f7f7;
      padding: 5px;
      font-size: 14px;
      margin-bottom: 10px;
    }

    .search-additions {
      min-height: 200px;
      width: 50vw;
      max-width: 680px;
      background: white;
      z-index: 1000000;
      position: absolute;
      top: 40px;
      box-shadow: 0px 8px 11px -3px #aaa7a7;
    }
  }

  .loading-bookmarks {
    img {
      height: 100px;
      opacity: 0.15;
      filter: grayscale(1);
      margin: auto;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }

  .view-profile-btn:hover {
    background-color: #1e90ff12;
    color: #6c757d;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 7.5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #b5b5b5;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #898989;
  }
}

.siimpl.sidebar-open {
  max-height: 100vh;
  overflow: hidden !important;
}

#popover-notications {
  width: 400px;
  max-width: 400px;
  min-height: 220px;

  .notification-container {
    .user-circle {
      width: 40px;
      height: 40px;
      background-color: dodgerblue;
      border-radius: 50%;

      p {
        font-size: 30px;
        color: white;
        text-transform: capitalize;
        margin-top: -3px !important;
      }
    }
  }
}

.notification_modal {
  .rodal-dialog {
    padding-top: 35px !important;
  }

  .rodal-close {
    width: 30px;
  }

  .notification-container {
    margin-top: 20px;
    background: #f0f0f0;
    border-radius: 5px;
    padding: 10px;

    p {
      font-weight: 300;
    }

    .user-circle {
      width: 40px;
      height: 40px;
      background-color: grey;
      border-radius: 50%;
      display: flex;

      p {
        font-size: 20px;
        color: white;
        text-transform: capitalize;
        margin: auto;
        font-weight: 300;
      }
    }
  }
}

.new-collection-wording {
  flex-direction: row;
  display: flex;
  margin: -10px 0px;
  color: dodgerblue;

  p {
    margin: auto 0px;
    font-size: 16px;
    font-family: cursive;
  }

  svg {
    width: 44px;
    height: 24px;
  }
}

#duplicatesModal {
  width: 350px;
  height: 220px;
  position: fixed;
  bottom: 10px;
  right: 20px;
  background: white;
  box-shadow: 1px 1px 21px -5px grey;
  border-radius: 5px;
  padding: 10px;
  z-index: 10000000000;

  .btn-primary {
    background: rgb(67, 160, 209) !important;
  }
}

@media (min-width: 1400px) {
  .sidebar-section {
    max-height: 60vh;
  }

  .siimpl-grid {
    grid-template-columns: repeat(4, minmax(240px, 1fr));
    grid-row: auto;
    grid-row-gap: 32px;
    grid-column-gap: 32px;
  }

  .sidebar-item {
    font-size: 15px;
  }
}

@media (min-width: 1600px) {
  .siimpl header .search .form-control {
    min-width: 500px;
  }
}

@media (min-width: 800px) and (max-width: 1599px) {
  .siimpl-grid {
    grid-template-columns: repeat(3, minmax(240px, 1fr));
    grid-row: auto;
    grid-row-gap: 12px;
    grid-column-gap: 12px;
  }

  .sidebar-item {
    font-size: 15px;

    svg {
      width: 17px;
      height: 17px;
    }
  }
}

@media (max-width: 1136px) {
  body {
    overflow: auto;
  }

  .siimpl {
    overflow: visible;

    .siimpl-container {
      overflow: visible;
    }
  }

  header {
    padding: 0px !important;

    .mob-menu-btn {
      display: block !important;
      padding-left: 10px;
      color: #000 !important;
      padding-top: 2.5px;
    }

    .logo {
      padding-left: 5px !important;
      display: none !important;
      padding-left: 1.5rem;
    }

    .explore-btn {
      display: none;
    }

    .search {
      padding: 15px 0;
      display: none !important;
      background: white;

      &.active {
        display: block !important;
      }

      .search-clear-btn {
        position: absolute;
        top: 20px;
        right: 15px;
        position: absolute;
        top: 15px;
        right: 5px;
      }

      .form-control {
        height: 40px !important;
        min-width: 300px !important;
        margin-bottom: 20px !important;
        font-size: 18px !important;
      }

      .search-result-container {
        position: unset !important;
        width: 100vw !important;
        overflow-y: auto;

        .result-item {
          position: relative;
          min-height: 65px;
          border-bottom: solid 0.5px lightgrey;
          padding-bottom: 30px !important;
          overflow: hidden;

          .title {
            font-size: 18px !important;
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .url {
            font-size: 14px !important;
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .collection {
            position: absolute;
            bottom: 10px;
            left: 5px;
            font-size: 14px !important;
          }
        }
      }
    }

    .search-additions {
      min-height: 200px;
      width: 50vw;
      max-width: 680px;
      background: white;
      z-index: 1000000;
      position: absolute;
      top: 40px;
      box-shadow: 0px 8px 11px -3px #aaa7a7;
    }

    .section-right {
      top: 10px;
      position: absolute;
      right: 0;
      min-width: 200px !important;

      .dropdown {
        .dropdown-toggle {
          margin: 0px !important;
        }
      }

      .help-btn {
        display: none;
      }

      .search-btn {
        display: block !important;
      }
    }
  }

  .overlay.active {
    height: 100%;
    width: 100vw;
    position: fixed;
    background: #8c8c8c;
    z-index: 10;
    opacity: 0.6;
    transition: 0.6s;
    z-index: 100;
    top: 0;
  }

  .siimpl-sidebar {
    width: 75vw !important;
    position: fixed !important;
    left: 0;
    bottom: 0;
    padding: 0px;
    overflow: auto;
    min-width: 310px;
    max-width: 310px;
    overflow-x: hidden;
    transform: translate(-100%, 0px);
    transition: 0.2s;
    z-index: 100;

    .mobile-sidebar-header {
      display: flex !important;
      min-height: 45px !important;
      border-bottom: solid 0.5px #efefef;
      margin-bottom: 10px;
    }

    .sidebar-section-bottom {
      overflow: visible !important;
    }

    .sidebar-section-bottom:hover {
      overflow: visible !important;
    }

    .collection-edit-btn {
      visibility: visible !important;
      opacity: 1 !important;
      margin-right: 5px;
    }

    span {
      width: 80%;
      float: left;
    }

    .sidebar-section:last-child {
      padding-bottom: 50px;
    }

    .sidebar-item {
      width: 315px;
      max-width: 100%;
      margin-right: 15px !important;
      padding-left: 4px;

      .ellipsis-icon {
        float: right !important;
        margin-right: 15px;
      }
    }

    .breaker {
      height: 50px;
    }
  }

  .sidebar-content {
    height: calc(100vh - 10px) !important;
  }

  .siimpl-sidebar.active {
    transform: translate(0%, 0px);
    transition: 0.2s;
  }

  .siimpl-item {
    max-width: calc(100vw - 25px);
    margin-bottom: 20px;
  }

  .siimpl-container-right {
    padding-left: 10px !important;
    padding-right: 10px !important;

    .styled-header {
      display: inline-block !important;
      width: 100%;
      text-transform: capitalize;

      p {
        display: inline-block;
      }

      .owner-circle {
        float: right;
      }

      .siimpl-sort {
        display: flex;
        margin: 10px 0;
        width: 100%;
      }
    }
  }

  .rodal-dialog:not(.collection_creator_modal .rodal-dialog) {
    width: 95% !important;
    height: 80% !important;
    overflow-x: hidden !important;
  }

  #popover-bookmark-add {
    max-width: 340px;
  }

  .tooltip:not(.helper) {
    display: none;
  }

  .multi-select {
    height: 80px !important;
    min-width: 0px !important;

    .btn {
      height: 50px;
    }
  }
}

@media (max-width: 800px) {
  #edit-snippet {
    padding: unset !important;
  }

  .rodal-dialog {
    padding-top: 30px !important;
  }

  .collection_creator_modal .rodal-close {
    top: 80px !important;
  }

  .siimpl header {
    .search-additions {
      width: 95vw;
      max-width: 100vw;
      top: 60px;
      margin-left: 10px;
    }

    .search {
      padding-left: 0px;

      .form-control {
        width: 95vw;
      }
    }
  }

  .logo {
    padding-left: 0rem !important;
    margin-bottom: 10px;
  }
}

@media (max-width: 1136px) {
  .siimpl {
    min-height: 100%;
    height: unset;
  }
}

.multi-select {
  height: 50px;
  position: fixed;
  bottom: 0;
  z-index: 10000000000;
  left: 0;
  right: 0;
  text-align: center;
  background: #00000057;
  width: 40%;
  padding: 13px;
  min-width: 500px;

  .btn {
    margin-bottom: 10px;
    background: white;
    margin-right: 15px;
  }

  .btn:hover:not(.bg-danger) {
    background: white !important;
  }
}

.popover-menu {
  font-family: sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  // visibility: hidden;
  position: absolute;
  z-index: 1000;
  top: 12%;
  width: 245px;
  padding: 32px;
  border-radius: 8px;
  /* opacity: 0; */
  background-clip: padding-box;
  background-color: #fff;
  color: #6e6d7a;

  box-shadow: 0px 10px 50px #0000001a;
}
