.App {
  text-align: center;
}

body {
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.dropdown-item {
  font-weight: 300;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.no-way {
  width: 60%;
  display: block !important;
  background-color: dodgerblue;
  color: white !important;
}

.yes-way {
  width: 50%;
  display: block !important;
  color: red !important;
}

.btn-primary {
  background: #2c77e7;
  color: white;
  border-color: transparent !important;
  border-radius: 5px;
}

ol,
ul {
  list-style: none;
}

.simplebar-content-wrapper {
  border: none !important;
  outline: none !important;
}

#flex-item {
  overflow: auto;
  height: 100%;
}

.simplebar-wrapper {
  ::-webkit-scrollbar {
    display: none !important;
  }
}

@media (min-width: 1136px) {
  .mobile-text-snippet {
    display: none !important;
  }
}

@media (max-width: 1135px) {
  input {
    font-size: 16px !important;
  }
}
