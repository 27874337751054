#settings {
  text-align: center;
  padding: 50px 20%;
  overflow: auto;

  .creator_container {
    display: flex;
    flex-direction: column;

    h1 {
      padding: 25px 10px 0px;
      font-size: 3.7rem;
      font-weight: 200;
    }

    h3 {
      font-weight: 200;
    }

    h6 {
      padding-bottom: 25px;
      font-weight: 300;
    }

    .nav-pills {
      border-radius: 30px;
      background: #dedede;
      width: 40%;
      display: flex;
      margin: auto;
      margin-bottom: 30px;

      .nav-item {
        flex: 1 1 auto;
      }

      .nav-link {
        border-radius: 30px;
        margin: 0;
        padding: 5px;
        height: 36px;
        width: auto;
        background: transparent !important;
        border: none !important;
        color: white;
      }

      .nav-link.active {
        background: linear-gradient(174deg, #bceaf1, #82cbff) !important;
      }
    }

    .tab-content {
      border: solid 0.5px #dedede;
      padding: 30px;
      border-radius: 5px;
      text-align: left;
    }

    .user-circle {
      width: 80px;
      height: 80px;
      background-color: dodgerblue;
      border-radius: 50%;

      p {
        font-size: 30px;
        color: white;
        text-transform: capitalize;
        margin-top: -3px !important;
      }
    }

    .is-adding {
      width: 50px;
      height: 50px;
      background: white;
      border: 0px;
      border-radius: 100% !important;
      border: solid 0.5px grey;
      text-align: center;
      margin-left: 10px;
      cursor: pointer;
      border-color: #e0e0e0;

      svg {
        stroke: black;
        vertical-align: sub;
        width: 18px;
      }
    }

    .is-adding:hover,
    .is-adding.active {
      background: linear-gradient(174deg, #bceaf1, #82cbff);
      border: 0px;

      svg {
        stroke: white;
      }
    }

    .creator-input-container {
      border: solid 1px #dedede;
      border-radius: 10px;
      margin: 0px auto 20px;
      text-align: center;
      min-height: 100px;
      padding: 5px 0;
      width: 100%;
      min-width: 300px;
      min-height: 50px;

      label {
        width: 100%;
        margin: 0;
        color: #898b8d;
        font-weight: 300;
      }

      input {
        border: none !important;
        background: transparent;
        outline: none !important;
        text-align: center;
        font-size: 20px;
        color: #5a5a5a;
        font-weight: 300;
        touch-action: manipulation;
      }

      .btn {
        width: auto;
        border-radius: 30px;
        margin: auto;
        font-size: 20px;
        font-weight: 200;
        padding: 5px 20px;
      }

      .btn-primary {
        background-color: dodgerblue;
        border: dodgerblue;
      }
    }

    .theme-container {
      img {
        width: 30%;
        box-shadow: 0px 0px 21px 2px #cecece;
        margin: auto 25px;
        cursor: pointer;
      }
    }

    #bookmark_importer {
      .text-left {
        text-align: center !important;
      }
    }

    .about {
      img {
        height: 100px;
        margin-bottom: 30px;
      }

      a {
        color: #9c9c9c;
      }
    }

    .creator-footer {
      margin-top: 50px;

      .btn {
        width: auto;
        min-width: 150px;
        border-radius: 30px;
        margin: 0 10px;
        font-size: 20px;
        font-weight: 200;
      }

      .btn-primary {
        background-color: dodgerblue;
        border: dodgerblue;
      }
    }
  }

  .platform-image {
    height: 3.5rem;
    margin: 0 0.7rem;
    opacity: 0.8;
    transition: all 0.3s;
  }

  .extensions {
    p {
      font-size: 12px;
      color: #565656;
      font-family: cursive;
      margin-bottom: 5px;
    }
  }
}

.collection_creator_modal {
  .rodal-close {
    position: absolute;
    cursor: pointer;
    top: 30px;
    right: 30px;
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 1136px) {
  #settings {
    padding: 50px 0 100px;

    .btn-group,
    .creator-input-container,
    .manage-user-container {
      width: 100% !important;
    }
  }

  .collection_creator_modal {
    .rodal-close {
      position: absolute;
      cursor: pointer;
      top: 50px !important;
      right: 20px !important;
      width: 20px !important;
      height: 20px !important;
    }
  }
}
