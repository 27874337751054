html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #0e1217;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

#drag-ghost {
  width: 200px;
  background-color: lightgrey;
  font-weight: 300;
  padding: 5px;
}

.hidden {
  display: none;
}

.btn,
button {
  outline: none !important;
  box-shadow: none !important;
  border-radius: 8px;
}

.btn-link {
  padding: 0px 5px;
  margin: 0px;
  text-decoration: none !important;
}

a {
  color: #55a7ff;
  text-decoration: none !important;
}

.copy-tooltip,
.tooltip {
  z-index: 10000000000000;
}

.icon {
  opacity: 1;
  width: 20px;
  top: 5px;
  left: 5px;
  position: absolute;
}

.multi-select-sticky {
  position: sticky;
  z-index: 100;
  top: 0px;
}

.multi-select-pane {
  width: 200px;
  height: 50px;
  background: white;
  position: absolute;
  right: 0px;
  z-index: 100;
  border-right: solid 3.5px #3eb8f7 !important;
  border: solid 0.5px #d6d6d6;
  box-shadow: 1px 2px 15px 2px #cacaca;
  text-align: center;
  padding: 10px;
}

.drag-pill {
  background-color: #dbe7ff;
  border: none;
  color: #6293ff;
  padding: 0px 17px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  border-radius: 16px;
  vertical-align: bottom;
  font-size: 13px;
}

.drag {
  font-size: 14px;
  background-color: grey;
  width: 220px;
  border-radius: 5px;
  padding: 5px;
  height: 50px;
  vertical-align: bottom;
}

.drag h6 {
  vertical-align: top;
  margin: auto;
}

.tippy-tooltip {
  font-weight: 500 !important;
  color: #6b6b6b !important;
  border-radius: 0 !important;
  font-size: 17px !important;
  background-color: #fff !important;
  box-shadow: 1px 2px 7px 2px grey;
  border-right: solid 5px #41b8f5;
}

.tippy-tooltip-content {
  font-size: 13px;
  font-weight: 300;
}

.tippy-popper[x-placement^='right'] [x-arrow] {
  border-right: 7px solid #ffffff !important;
}

h6 {
  color: #6b6b6b;
}

.form-control {
  border-radius: 0px;
  box-shadow: none !important;
  outline: 0px !important;
  border: solid 0.5px #dcdcdc;
}

.css-1vmnjpn-skeletonStyles-Skeleton {
  border-radius: 0px !important;
}

.Toastify__toast-container {
  z-index: 1000000000000 !important;
}

.Toastify__toast-container--top-right {
  top: 2.5em !important;
  right: 0em !important;
}

.Toastify__toast--default {
  border-radius: 5px !important;
}

.rodal {
  z-index: 1000000001 !important;
}

.dropdown-menu.show {
  border: solid 1px #2b2b2b !important;
  margin-top: 5px !important;
  z-index: 1100;
}

.btn-outline-secondary.dropdown-toggle {
  background-color: transparent !important;
  color: #6c757d !important;
}

.swal2-container {
  z-index: 100000000000 !important;
}

.swal2-popup {
  background-color: #2d3238 !important;
}

.swal2-title {
  color: white;
  font-weight: 300 !important;
}

.swal2-content {
  color: white;
  font-size: 16px;
}

.swal2-confirm {
  margin-bottom: 20px;
  color: red;
}

.tawk-text-bold-3 {
  font-size: 1rem;
}

::placeholder {
  color: #000000 !important;
  opacity: 0.4;
}

.wpcc-container {
  z-index: 10000000000 !important;
}
