.preview {
  max-width: 330px;
  position: relative;
  margin-bottom: 20px;

  .favourite-button {
    display: none;
  }

  :hover {
    .favourite-button {
      display: inline-flex;
      transform: translate(5px, -2px);
    }
  }

  .preview-inner {
    padding: 10px 10px 28px;
    background: #f3f3f3;
    border-radius: 8px;

    .img-container {
      width: 100%;
      border-radius: 10px;
      margin-bottom: 20px;
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;
      display: flex;
      justify-content: center;

      img {
        max-width: 100%;
      }
    }

    .card-title {
      margin-right: auto;
      padding-top: 1px;
      font-size: 14px;
      line-height: 1.5;
      font-weight: 500;
      margin-bottom: 0.15rem;
      word-break: break-word;
    }

    .link {
      font-size: 0.75rem;
      word-wrap: break-word;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      .card-url {
        color: #55a7ff;
        margin: 0;
      }
    }

    .card-wording {
      max-height: 140px;
      font-size: 12.5px;
      line-height: 1.66667;
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
    }

    .card-tags {
      display: inline-block;

      .tag {
        font-weight: 500;
        border-radius: 5px;
        float: left;
        border: solid 2px #45b26b;
        padding: 0 10px;
        color: #45b26b;
        margin-right: 10px;
        margin-bottom: 5px;
      }
    }

    .card-foot {
      position: absolute;
      bottom: 5px;
      padding-right: 25px;

      .bookmark-icon {
        visibility: hidden;
        opacity: 0;
      }

      .home-screen-collection-indicator {
        position: absolute;
        bottom: 5px;
      }

      .option-container {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }

  .preview-inner.selected {
    border: solid 1px dodgerblue;
  }
}

.preview:hover {
  .card-foot .bookmark-icon {
    visibility: visible;
    opacity: 1;
  }
}

.bookmark-note-preview {
  padding: 15px;
  font-size: 16px;
}

@media (min-width: 1400px) {
  .preview .preview-inner {
    min-width: 250px;
  }
}
