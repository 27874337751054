[data-type='dark-theme'] {
  background: #0e1217;
  color: white !important;

  .logo {
    color: white !important;
  }

  .sidebar-item {
    background-color: transparent;
  }

  .styled-header,
  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .editor-title,
  .collection-title,
  .dropdown-item,
  #edit-snippet {
    color: #ebebeb;
    font-weight: 400px;
  }

  .app-store-btn {
    p,
    h5 {
      color: black !important;
    }
  }

  svg {
    stroke: #ebebeb !important;
    stroke-width: 2px;
  }

  .is-adding,
  .is-shared,
  .notification-container {
    background: #444444 !important;
    border-color: #444444 !important;
  }

  .siimpl-item,
  .siimpl-item-list {
    border-color: #464646 !important;
  }

  .dropdown-menu {
    background: #15191f !important;
    outline: solid 1px rgba(31, 41, 55, 0.9490196078);

    .dropdown-item:hover {
      background: #15191f !important;
    }
  }

  .rodal-dialog {
    background: #0e1217;
  }

  .search .form-control {
    background: #0e1217 !important;
    border: solid 0.5px #464646 !important;
  }

  input,
  textarea {
    background-color: #333840 !important;
    color: white !important;
  }

  ::placeholder {
    color: #a0a0a0 !important;
  }

  .theme-container img {
    box-shadow: 0px 0px 14px 6px #252525 !important;
  }

  .collections-container {
    border: none !important;

    .item {
      background-color: #292929 !important;
    }
  }

  .preview-inner {
    box-shadow: unset;
  }

  .my-masonry-grid .my-masonry-grid_column .siimpl-item,
  .preview-inner,
  .creator-input-container,
  .copy-button {
    background: #15191f !important ;
    outline: solid 1px #1f2937f2;
  }

  .collection-selector-wrapper {
    background: #333840 !important ;

    .collection-selector-item:hover,
    .collection-selector-item.active {
      background: #4b4c64 !important;
    }
  }

  .siimpl-sidebar {
    background: #0e1217 !important;
  }

  label {
    color: white;
  }

  .snippet-selector,
  .snippet-selector-header,
  .snippet-item {
    border-color: #626262 !important;
  }

  .user-details {
    color: white;
  }

  .modal-container {
    background: #2d3238 !important;
  }

  @media (max-width: 1135px) {
    .my-masonry-grid .my-masonry-grid_column .siimpl-item {
      background: #363636 !important;
    }
  }
}
