.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  width: auto;
  margin-top: 16px;
  padding-bottom: 50px;
  max-width: calc(100vw - 320px);
  min-height: calc(100vh - 286px);
  gap: 16px;

  .my-masonry-grid_column {
    background-clip: padding-box;

    .siimpl-item {
      position: relative;
      height: auto !important;
      cursor: pointer;
      text-decoration: none;
      color: #212121;
      border-radius: 5px;
      background: #f3f3f3;
      padding: 10px;
      padding-bottom: 20px;
      margin-bottom: 25px;

      img {
        width: 100%;
        object-fit: scale-down;
        border-radius: 7.5px;
        margin-bottom: 10px;
      }

      .empty-img {
        display: none;
      }

      .title p {
        font-size: 0.85rem;
        line-height: 1.2;
        color: #515151;
        margin-bottom: 5px;
        text-overflow: ellipsis;
        word-wrap: break-word;
      }

      .link {
        font-size: 0.75rem;
        word-wrap: break-word;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .description {
        font-size: 0.75rem;
        line-height: 1.2;
        color: #cbcbcb;
        max-height: 120px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        margin-bottom: 30px;
      }

      .details {
        position: absolute;
        color: #cbcbcb;
        font-size: 0.75rem;
        bottom: 5px;
        width: calc(100% - 20px);
      }

      .color {
        background: #47bbff;
        width: 20%;
        height: 7px;
        border-radius: 45px;
        margin: auto 0;
      }
    }

    [data-grid='grid'] {
      .preview-inner {
        margin-bottom: 20px;
      }

      .img-container {
        height: 130px;
      }

      .card-tags {
        display: none;
      }

      .card-title,
      .link {
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2 !important;
        -webkit-box-orient: vertical;
      }

      .card-wording {
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4 !important;
        -webkit-box-orient: vertical;
      }

      .siimpl-img {
        img {
          width: 100%;
          object-fit: cover;
          border-radius: 7.5px;
          margin-bottom: 10px;
          max-height: 130px;
          overflow: hidden;
        }
      }
    }
  }
}

.placeholder-ghost {
  .siimpl-item-wrapper {
    min-height: 220px;
    border: solid 0.5px #e8e8e8;
    padding: 30px 10px;
    border-radius: 5px;
  }
}

@media (max-width: 1136px) {
  .my-masonry-grid {
    max-width: 100vw !important;

 
  }
}
